import { graphql } from 'gatsby'
import React, { useMemo, useRef, useState } from 'react'

import { CaseContent } from '../../@import-types/contentful/Case.types'
import { OurWorkPageType } from '../../@import-types/contentful/OurWorkPage.types'
import Layout from '../../components/Layout'
import CaseTeaserCard from '../../components/_cards/CaseTeaserCard'
import FilterDropdown from '../../components/_elements/FilterDropdown'
import FilterHero from '../../components/_heroes/FilterHero'
import TeaserGridLayout from '../../components/_layouts/TeaserGridLayout'
import { RootPageQuery } from '../../graphql/global.graphql'
import {
  INTERNAL_TAG_CLIENT_PREFIX,
  useFilteredEntriesByTag,
} from '../../hooks/content/useFilteredEntiresByTag'
import useSortedEntries from '../../hooks/content/useSortedEntries'
import { useInputOptionsFromTags } from '../../hooks/content/useTagOptions'
import useLowercaseQueryObject from '../../hooks/routing/useLowercaseQueryObject'
import useFadeTransitionItems from '../../hooks/useFadeTransitionItems'
import useMatchesMedia from '../../hooks/useMatchesMedia'

type OurWorkPageProps = RootPageQuery<{
  contentfulOurWorkPage: OurWorkPageType
  allContentfulCasePage: {
    edges: Array<{
      node: CaseContent
    }>
  }
}>

export const clientQueryKey = 'client'

export default function OurWorkPage({
  data,
  pageContext,
  location,
}: OurWorkPageProps) {
  const { contentfulOurWorkPage, allContentfulCasePage } = data

  const {
    title,
    heroSubtitle,
    heroFilterPreamble,
    featuredCases,
    showCasesWithTags,
    sortingOrder,
  } = contentfulOurWorkPage

  const queries = useLowercaseQueryObject(location.search)

  const allCases = useMemo(
    () => allContentfulCasePage.edges.map((edge) => edge.node),
    [allContentfulCasePage]
  )

  const allTags = useMemo(() => {
    const allTags = showCasesWithTags ? [...showCasesWithTags] : []

    if (clientQueryKey in queries) {
      const clientTagName = decodeURIComponent(queries[clientQueryKey])
      allTags.push({
        value: `${INTERNAL_TAG_CLIENT_PREFIX}${clientTagName}`,
        text: clientTagName,
      })
    }

    return allTags
  }, [showCasesWithTags, queries])

  const options = useInputOptionsFromTags(allTags)

  const [selectedFilter, setSelectedFilter] = useState(() => {
    if (clientQueryKey in queries) {
      return options[options.length - 1].value
    }

    return options[0]?.value
  })

  const selectedCases = useFilteredEntriesByTag(allCases, selectedFilter)

  const sortedSelectedCases = useSortedEntries(selectedCases, sortingOrder)

  const gridContainerRef = useRef<HTMLDivElement>(null)

  const isGrid = useMatchesMedia('(min-width: 650px)', true)

  const cacheValuesBeforeChange = useFadeTransitionItems({
    containerElRef: gridContainerRef,
    animateContainerHeight: false,
    durationMultiplier: 1.5,
    forceCloneSize: true,
    scale: 0.025,
  })

  function onFilterChange(value: string) {
    cacheValuesBeforeChange()
    setSelectedFilter(value)
  }

  return (
    <Layout
      data={data}
      basePageContent={data.contentfulOurWorkPage}
      pageContext={pageContext}
    >
      {title && (
        <FilterHero
          title={title}
          subtitle={heroSubtitle}
          filterPreamble={heroFilterPreamble}
          filter={
            <FilterDropdown
              value={selectedFilter}
              options={options}
              onChange={onFilterChange}
            />
          }
        />
      )}

      <TeaserGridLayout forwardRef={gridContainerRef}>
        {sortedSelectedCases.map((caseContent) => (
          <CaseTeaserCard
            key={caseContent.id}
            caseContent={caseContent}
            isImageOnly={
              isGrid &&
              !featuredCases?.some(
                (featuredCase) => featuredCase.id === caseContent.id
              )
            }
          />
        ))}
      </TeaserGridLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query OurWorkPageById(
    $id: String!
    $startPageId: String!
    $locale: String!
    $tags: [String]!
  ) {
    contentfulOurWorkPage(id: { eq: $id }) {
      ...BasePageFragment

      title
      heroSubtitle
      heroFilterPreamble

      showCasesWithTags {
        text
        value
      }

      featuredCases {
        id
      }

      sortingOrder {
        id
      }
    }

    allContentfulCasePage(
      filter: {
        node_locale: { eq: $locale }
        tags: { elemMatch: { value: { in: $tags } } }
        slug: { ne: null }
        projectName: { ne: null }
      }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          ...CaseTeaserFragment
        }
      }
    }

    ...GlobalFragment
  }
`
