import React from 'react'

import Colors from '../../../constants/Colors'
interface SvgArrowRightLongProps {
  color?: string | null
}

export default function SvgArrowRightLong({
  color,
  ...svgProps
}: SvgArrowRightLongProps & Omit<JSX.IntrinsicElements['svg'], 'color'>) {
  const renderColor = color ?? Colors.Brown

  return (
    <svg width="51" height="30" viewBox="0 0 51 30" fill="none" {...svgProps}>
      <path d="M0 15L48 15" stroke={renderColor} strokeWidth="2" />
      <path
        d="M40 6L48 15L40 24"
        stroke={renderColor}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}
