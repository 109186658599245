import classNames from 'classnames'
import Img from 'gatsby-image'
import React from 'react'

import { CaseContent } from '../../../@import-types/contentful/Case.types'
import Colors from '../../../constants/Colors'
import useCaseLink from '../../../hooks/routing/useCaseLink'
import { getCaseColor, getComplementaryColor } from '../../../utils/colorUtils'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import SvgArrowRightLong from '../../_svg/SvgArrowRightLong'

import styles from './case-teaser-card.module.scss'

interface CaseTeaserCardProps {
  caseContent: CaseContent

  isImageOnly?: boolean

  className?: string
}

export default function CaseTeaserCard({
  caseContent,

  isImageOnly = false,

  className,
}: CaseTeaserCardProps) {
  const caseLink = useCaseLink(caseContent)

  const { teaserImage, heroImage, client, teaserTitle, projectName } =
    caseContent

  const renderImage = teaserImage && teaserImage.fluid ? teaserImage : heroImage
  const fluidImage = renderImage?.fluid
  const hasImage = Boolean(fluidImage)

  const backgroundColor = getCaseColor(caseContent) ?? Colors.Orange
  const textColor = getComplementaryColor(backgroundColor)

  const clientName = client?.name
  const renderTitle = teaserTitle ?? projectName

  return (
    <InternalLink
      to={caseLink!}
      style={{ backgroundColor: !hasImage ? backgroundColor : undefined }}
      className={classNames(
        styles.container,
        isImageOnly && hasImage && styles.containerIsImageOnly,
        className
      )}
      segmentdata={{
        anchor_text: clientName + ': ' + renderTitle,
        position: 'body',
        url: caseLink,
      }}
    >
      {hasImage && (
        <Img
          className={styles.image}
          fluid={fluidImage}
          Tag="span"
          alt={renderImage?.description || renderImage?.title}
        />
      )}

      <span className={styles.colorBackground} style={{ backgroundColor }} />

      <span
        className={styles.content}
        style={{
          backgroundColor: isImageOnly ? undefined : backgroundColor,
          color: textColor,
        }}
      >
        {clientName && <span className={styles.clientName}>{clientName}</span>}

        <span className={styles.title}>{renderTitle}</span>

        <span className={styles.arrowContainer}>
          <SvgArrowRightLong className={styles.arrow} color={textColor} />
        </span>
      </span>
    </InternalLink>
  )
}
